/** @returns the list of viewports for mobile, tablet and desktop */
export function getViewports() {
  // Taken from https://storybook.js.org/docs/essentials/viewport#use-a-detailed-set-of-devices
  return {
    mobileSmall: {
      name: 'Mobile (small)',
      styles: {
        // iPhone 5
        width: '320px',
        height: '568px',
      },
    },
    mobileLarge: {
      name: 'Mobile (large)',
      styles: {
        // iPhone 12 Pro Max
        width: '428px',
        height: '926px',
      },
    },
    tablet: {
      name: 'Tablet',
      styles: {
        // iPad
        width: '768px',
        height: '1024px',
      },
    },
  };
}

/**
 * If attached to a component, it's ignored in Chromatic screenshot diffs.
 * Useful for non-static elements (e.g. shimmering skeletons).
 *
 * @example
 * <View {...ignoreInChromaticDiff} />
 *
 * @see https://www.chromatic.com/docs/ignoring-elements
 */
export const ignoreInChromaticDiff = {
  'data-chromatic': 'ignore',
};
