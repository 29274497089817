import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

import { theme } from '../../theme';
import { borderRadiuses } from '../../theme/border';
import { ignoreInChromaticDiff } from '../../utils/storybook';
import { WithCommonProps } from '../../utils/types';

const ANIMATION_DURATION_MS = 2000;

type Props = WithCommonProps<{
  height: number | `${number}%`;
  width: number | `${number}%`;
  /** default: '$xs' */
  borderRadius?: keyof typeof borderRadiuses;
}>;

// TODO If we get a use case for multiple skeletons rendered at different times,
// we need to find a way to share the animation timing amongst all those skeletons.

/** Renders a subtly-pulsating grey box to indicate a loading state. Also sometimes called a "shimmer". */
export function Skeleton({
  borderRadius = '$xs',
  height,
  width,
  testID,
}: Props) {
  // See docs: https://reactnative.dev/docs/animated
  const animatedOpacity = useRef(new Animated.Value(1)).current;

  const fadeIn = Animated.timing(animatedOpacity, {
    toValue: 1,
    duration: ANIMATION_DURATION_MS / 2,
    useNativeDriver: true,
  });
  const fadeOut = Animated.timing(animatedOpacity, {
    toValue: 0.4,
    duration: ANIMATION_DURATION_MS,
    useNativeDriver: true,
  });

  useEffect(() => {
    // The double fade-in is to prevent an abrupt transition
    const animation = Animated.loop(
      Animated.sequence([fadeIn, fadeOut, fadeIn]),
    );
    animation.start();
    return () => animation.stop();
  }, [fadeIn, fadeOut]);

  return (
    <Animated.View
      style={{
        ...theme.borders[borderRadius],
        height,
        width,
        backgroundColor: theme.colors.$neutral.$200,
        opacity: animatedOpacity,
      }}
      testID={testID}
      {...ignoreInChromaticDiff}
    />
  );
}
