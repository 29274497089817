import * as Dripsy from 'dripsy';
import { useDripsyTheme } from 'dripsy';
import { type PropsWithChildren, useState } from 'react';

import { CustomSxProp, ThemeProvider, Variants } from '../../theme';
import { WithCommonProps } from '../../utils/types';

export type CardProps = WithCommonProps<{
  /** If supplied, the whole card becomes a clickable button */
  onPress?: () => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  sx?: CustomSxProp;
}>;

/**
 * A component that displays text, images, buttons, lists, etc in a card.
 * The structure of a card should be:
 * - `CardHeader` (optional) - a component that is rendered at the top.
 * - `CardContent` - a component that contains the body of the card.
 * - `CardFooter` (optional) - a component that is rendered at the bottom.
 */
export const Card = ({
  children,
  onPress,
  onHoverIn,
  onHoverOut,
  sx,
  testID,
  accessibilityLabel,
}: PropsWithChildren<CardProps>) => {
  // This is hardcoded since we only have 1 kind of card at the moment
  const variants: Variants<'cards'> = ['primary'];

  const [isHovered, setIsHovered] = useState(false);
  if (isHovered) variants.push('primaryHover');

  const { theme } = useDripsyTheme();
  const customSx = typeof sx === 'function' ? sx(theme) : sx;
  const props = {
    variants,
    sx: customSx,
    testID,
    'aria-label': accessibilityLabel,
  };

  if (onPress) {
    return (
      <ThemeProvider>
        <ThemedPressableCard
          {...props}
          onPress={onPress}
          onHoverIn={() => {
            setIsHovered(true);
            onHoverIn?.();
          }}
          onHoverOut={() => {
            setIsHovered(false);
            onHoverOut?.();
          }}
        >
          {children}
        </ThemedPressableCard>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <ThemedCard {...props}>{children}</ThemedCard>
    </ThemeProvider>
  );
};

const ThemedCard = Dripsy.styled(Dripsy.View, { themeKey: 'cards' })();
const ThemedPressableCard = Dripsy.styled(Dripsy.Pressable, {
  themeKey: 'cards',
})();
