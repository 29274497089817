import * as Dripsy from 'dripsy';
import { type PropsWithChildren } from 'react';

import { CustomSxProp } from '../../theme';

type CardContentProps = {
  sx?: CustomSxProp;
};

export const CardContent = ({
  sx,
  children,
}: PropsWithChildren<CardContentProps>) => (
  <Dripsy.View sx={sx}>{children}</Dripsy.View>
);
