import { DripsyBaseTheme } from 'dripsy';

export const spacings = {
  $0: 0,
  $4: 4,
  $8: 8,
  $12: 12,
  $16: 16,
  $24: 24,
  $32: 32,
  $40: 40,
  $48: 48,
  $64: 64,
  $80: 80,
  $96: 96,
  $120: 120,
  $144: 144,
} satisfies DripsyBaseTheme['space'];
