import * as Dripsy from 'dripsy';
import { useDripsyTheme } from 'dripsy';
import { Children, type PropsWithChildren } from 'react';

import { CustomSxProp, ThemeProvider } from '../../theme';
import * as BorderTheme from '../../theme/border';
import { WithCommonProps } from '../../utils/types';

type ListProps = WithCommonProps<{
  /**
   * If set, line separators are rendered in between `ListItem`
   * components using this border color variant.
   */
  separatorColor?: keyof typeof BorderTheme.borderColors;
  sx?: CustomSxProp;
}>;

/**
 * A component that contains an enumeration of repeated items.
 * Each item should be encapsulated within a `ListItem` component.
 */
export const List = ({
  children,
  separatorColor,
  sx,
  testID,
}: PropsWithChildren<ListProps>) => {
  const { theme } = useDripsyTheme();
  return (
    <ThemeProvider>
      <Dripsy.View sx={sx} testID={testID}>
        {Children.map(children, (child, index) => (
          <Dripsy.View
            sx={
              // Separators are only in between items
              separatorColor && index > 0
                ? { ...theme.borders[separatorColor], borderTopWidth: 1 }
                : undefined
            }
          >
            {child}
          </Dripsy.View>
        ))}
      </Dripsy.View>
    </ThemeProvider>
  );
};
