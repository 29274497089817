import { DripsyProvider, makeTheme, StyledProps, Sx } from 'dripsy';

import { borderColors, borderRadiuses } from './border';
import {
  buttonShapes,
  buttonSizes,
  buttonStates,
  buttonVariants,
} from './button';
import { cards } from './card';
import { colors } from './color';
import { fontScales, fontWeights } from './font';
import { layoutContainers, layoutMargins } from './layout';
import { sizes } from './size';
import { spacings } from './spacing';
import { textLabelVariants, textSizeVariants, textVariants } from './text';

export const theme = makeTheme({
  colors,
  borders: { ...borderColors, ...borderRadiuses },
  buttons: {
    ...buttonVariants,
    ...buttonStates,
    ...buttonShapes,
    ...buttonSizes,
  },
  text: { ...textVariants, ...textSizeVariants, ...textLabelVariants },
  customFonts: {
    Inter: {
      '100': 'Inter_100Thin',
      '200': 'Inter_200ExtraLight',
      '300': 'Inter_300Light',
      '400': 'Inter_400Regular',
      '500': 'Inter_500Medium',
      '600': 'Inter_600SemiBold',
      '700': 'Inter_700Bold',
      '800': 'Inter_800ExtraBold',
      '900': 'Inter_900Black',
    },
  },
  fonts: { root: 'Inter' },
  fontWeights,
  fontSizes: fontScales,
  space: spacings,
  cards,
  sizes,
  layout: { ...layoutMargins, ...layoutContainers },
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return <DripsyProvider theme={theme}>{children}</DripsyProvider>;
}

type UnloanV3Theme = typeof theme;
/**
 * We want to prevent global override of the `DripsyFinalTheme` from polluting our `sx` prop
 * (e.g. overrides from the other package `@unloan/common-ui`). Hence why we explicitly set
 * it to the theme of this package.
 */
export type CustomSxProp = Sx | ((theme: UnloanV3Theme) => Sx);

declare module 'dripsy' {
  interface DripsyCustomTheme extends UnloanV3Theme {}
}

export type Variant<T extends keyof UnloanV3Theme> = StyledProps<T>['variant'];
export type Variants<T extends keyof UnloanV3Theme> = Exclude<
  Variant<T>,
  undefined
>[];
