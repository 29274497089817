import * as Dripsy from 'dripsy';
import { type PropsWithChildren } from 'react';

import { CustomSxProp } from '../../theme';

type CardHeaderProps = {
  sx?: CustomSxProp;
};

export const CardHeader = ({
  sx,
  children,
}: PropsWithChildren<CardHeaderProps>) => (
  <Dripsy.View sx={sx}>{children}</Dripsy.View>
);
