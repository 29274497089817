import * as Dripsy from 'dripsy';
import type { PropsWithChildren } from 'react';

import { ThemeProvider } from '../../theme';
import * as FontTheme from '../../theme/font';
import * as TextTheme from '../../theme/text';
import { WithCommonProps } from '../../utils/types';

type TextProps = WithCommonProps<{
  /** default: 'primary' */
  variant?: keyof typeof TextTheme.textVariants;
  /** default: 'body1' */
  size?: keyof typeof TextTheme.textSizeVariants;
  /** default: 'semiBold' for display/heading and 'regular' for body/label */
  weight?: keyof typeof FontTheme.fontWeights;
  textAlign?: 'center' | 'justify' | 'left' | 'right';
}>;

export const Text = ({
  children,
  variant = 'primary',
  size = 'body1',
  weight,
  textAlign,
  testID,
}: PropsWithChildren<TextProps>) => {
  const variants = [variant, size];
  return (
    <ThemeProvider>
      <ThemedText
        variants={variants}
        sx={{ fontWeight: weight, textAlign }}
        testID={testID}
      >
        {children}
      </ThemedText>
    </ThemeProvider>
  );
};

const ThemedText = Dripsy.styled(Dripsy.Text, { themeKey: 'text' })();
