import * as Dripsy from 'dripsy';
import { useDripsyTheme } from 'dripsy';
import { type PropsWithChildren } from 'react';

import { CustomSxProp } from '../../theme';
import { WithCommonProps } from '../../utils/types';

type ListItemProps = WithCommonProps<{
  /** If supplied, the list item clickable button */
  onPress?: () => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  sx?: CustomSxProp;
}>;

export const ListItem = ({
  children,
  onPress,
  onHoverIn,
  onHoverOut,
  sx,
  testID,
  accessibilityLabel,
}: PropsWithChildren<ListItemProps>) => {
  const { theme } = useDripsyTheme();
  const customSx = typeof sx === 'function' ? sx(theme) : sx;
  const props = {
    sx: customSx,
    testID,
    'aria-label': accessibilityLabel,
  };

  if (onPress) {
    return (
      <Dripsy.Pressable
        {...props}
        onPress={onPress}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        {children}
      </Dripsy.Pressable>
    );
  }
  return <Dripsy.View {...props}>{children}</Dripsy.View>;
};
