import { ReactNode } from 'react';

/** Generates an accessibility label based on the children node. Primarily for buttons. */
export function getAccessibilityLabel(children: ReactNode): string {
  if (children === undefined || children === null) return '';
  if (Array.isArray(children)) {
    return children
      .map((child) => {
        if (child === undefined || child === null) return '';
        if (typeof child === 'object') return getAccessibilityLabel(child);
        return child.toString();
      })
      .join(' - ');
  }
  return children.toString();
}
