import { DripsyBaseTheme } from 'dripsy';

import { borderRadiuses } from './border';
import { colors } from './color';
import { shadows } from './shadows';

export const cards = {
  primary: {
    // Unfortunately, `shadowProps` are only supported on IOS (see https://reactnative.dev/docs/shadow-props)
    // The only configurable parameter for Android is https://reactnative.dev/docs/view-style-props#elevation-android
    elevation: 3,
    backgroundColor: colors.$neutral.$000,
    ...borderRadiuses.$md,
    ...shadows.$300,
  },
  primaryHover: {
    elevation: 6,
    ...shadows.$500,
  },
} satisfies DripsyBaseTheme['cards'];
