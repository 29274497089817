import * as Dripsy from 'dripsy';
import { type PropsWithChildren } from 'react';

import { CustomSxProp } from '../../theme';

type CardFooterProps = {
  sx?: CustomSxProp;
};

export const CardFooter = ({
  sx,
  children,
}: PropsWithChildren<CardFooterProps>) => (
  <Dripsy.View sx={sx}>{children}</Dripsy.View>
);
