import { DripsyBaseTheme } from 'dripsy';

export const fontScales = {
  $10: 10,
  $12: 12,
  $14: 14,
  $16: 16,
  $20: 20,
  $24: 24,
  $28: 28,
  $32: 32,
  $36: 36,
  $40: 40,
  $44: 44,
  $48: 48,
  $52: 52,
  $56: 56,
  $60: 60,
  $64: 64,
  $72: 72,
  $76: 76,
} satisfies DripsyBaseTheme['fontSizes'];

export const fontWeights = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
} satisfies DripsyBaseTheme['fontWeights'];
