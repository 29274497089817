import * as Dripsy from 'dripsy';
import { Children, PropsWithChildren, useState } from 'react';
import { PressableProps } from 'react-native';

import { ThemeProvider, Variants } from '../../theme';
import * as ButtonTheme from '../../theme/button';
import * as TextTheme from '../../theme/text';
import { getAccessibilityLabel } from '../../utils/accessibility';
import { WithCommonProps } from '../../utils/types';

type LabelSize = keyof typeof TextTheme.textSizeVariants;
type ButtonSize = keyof typeof ButtonTheme.buttonSizes;
type ButtonProps = WithCommonProps<{
  /** default: 'primary' */
  variant?: keyof typeof ButtonTheme.buttonVariants;
  /** default: 'md' */
  size?: ButtonSize;
  /** default: 'square' */
  shape?: keyof typeof ButtonTheme.buttonShapes;
  disabled?: boolean;
  active?: boolean;
  /**
   * If set to true, occupies the full width of the parent container and with a centered label.
   * Doesn't do anything for icon-only buttons.
   * default: false
   */
  stretched?: boolean;
  onPress: () => void;
}>;

/**
 * Note that this renders a circular button that if
 * `size: 'icon' or 'iconCompact'` (the latter doesn't add any padding).
 */
export const Button = ({
  children,
  onPress,
  variant = 'primary',
  size = 'md',
  shape = 'square',
  disabled,
  active,
  stretched,
  accessibilityLabel,
  testID,
}: PropsWithChildren<ButtonProps>) => {
  const buttonVariants: Variants<'buttons'> = [variant, shape, size];
  const labelVariants: Variants<'text'> = [`${variant}Label`];

  const [isPressedIn, setIsPressedIn] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  if (disabled) {
    buttonVariants.push(`${variant}Disabled`);
    labelVariants.push(`${variant}DisabledLabel`);
  } else if (active) {
    buttonVariants.push(`${variant}Active`);
    labelVariants.push(`${variant}ActiveLabel`);
  } else if (isHovered || isPressedIn) {
    buttonVariants.push(`${variant}Hover`);
    labelVariants.push(`${variant}HoverLabel`);
  }

  const accessibilityLabelText =
    accessibilityLabel ?? getAccessibilityLabel(children);

  const eventListeners: PressableProps = {
    onHoverIn: () => setIsHovered(true),
    onHoverOut: () => setIsHovered(false),
    onPressIn: () => setIsPressedIn(true),
    onPressOut: () => setIsPressedIn(false),
    onPress,
  };

  // Icon-only buttons are a bit of a special case since they are always circular
  // and the icon isn't rendered as Text.
  if (size === 'icon' || size === 'iconCompact') {
    return (
      <ThemeProvider>
        <ThemedButton
          {...eventListeners}
          variants={buttonVariants}
          disabled={disabled}
          testID={testID}
          accessibilityLabel={accessibilityLabelText}
          aria-label={accessibilityLabelText}
        >
          <Dripsy.View>{children}</Dripsy.View>
        </ThemedButton>
      </ThemeProvider>
    );
  }

  labelVariants.unshift(LABEL_SIZES_BY_BUTTON_SIZE[size]);

  let sx: Dripsy.Sx = {};
  // Full-width with a centered label
  if (stretched) sx = { width: '100%', alignItems: 'center' };

  return (
    <ThemeProvider>
      <ThemedButton
        {...eventListeners}
        testID={testID}
        variants={buttonVariants}
        disabled={disabled}
        accessibilityLabel={accessibilityLabelText}
        aria-label={accessibilityLabelText}
        sx={sx}
      >
        <ThemedLabelContainer
          variants={labelVariants}
          // Align the items vertically and with ample horizontal spacing in between
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.space.$8,
          })}
        >
          {Children.map(children, (child) =>
            // Rendering strings directly throws an error/warning so we wrap them in <Text>
            typeof child === 'string' ? (
              <Dripsy.Text variants={labelVariants}>{child}</Dripsy.Text>
            ) : (
              child
            ),
          )}
        </ThemedLabelContainer>
      </ThemedButton>
    </ThemeProvider>
  );
};

// This dictionary ensures that any new button size variants need to be mapped to a label size
const LABEL_SIZES_BY_BUTTON_SIZE = {
  lg: 'label1',
  md: 'label2',
  sm: 'label3',
  xs: 'label3',
  icon: undefined, // icon-only buttons have no label
  iconCompact: undefined,
} satisfies Record<ButtonSize, LabelSize | undefined>;

const ThemedButton = Dripsy.styled(Dripsy.Pressable, { themeKey: 'buttons' })();
const ThemedLabelContainer = Dripsy.styled(Dripsy.View, { themeKey: 'text' })();
