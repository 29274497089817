import * as Dripsy from 'dripsy';
import type { ImagePropsBase, ImageSourcePropType } from 'react-native';

import { CustomSxProp, ThemeProvider } from '../../theme';
import { WithCommonProps } from '../../utils/types';

type ImageProps = WithCommonProps<{
  source: ImageSourcePropType;
  sx?: CustomSxProp;
  /** `default: 'contain'` See https://reactnative.dev/docs/image#resizemode for more details */
  resizeMode?: ImagePropsBase['resizeMode'];
  alt?: ImagePropsBase['alt'];
}>;

export const Image = ({
  sx,
  source,
  resizeMode = 'contain',
  testID,
  alt,
}: ImageProps) => (
  <ThemeProvider>
    <Dripsy.Image
      source={source}
      sx={sx}
      resizeMode={resizeMode}
      testID={testID}
      alt={alt}
    />
  </ThemeProvider>
);
