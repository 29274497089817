import { DripsyBaseTheme } from 'dripsy';

import { colors } from './color';
import { fontScales, fontWeights } from './font';

export const textVariants = {
  primary: { color: colors.$neutral.$1000 },
  secondary: { color: colors.$neutral.$800 },
  secondaryDisabled: { color: colors.$neutral.$500 },
  tertiary: { color: colors.$neutral.$600 },
  tertiaryDisabled: { color: colors.$neutral.$300 },
  link: { color: colors.$blue.$400 },
  linkHover: { color: colors.$blue.$300 },
  linkActive: { color: colors.$blue.$500 },
  success: { color: colors.$green.$400 },
  warning: { color: colors.$orange.$400 },
  error: { color: colors.$red.$400 },
  special: { color: colors.$brink.$400 },
  specialSecondary: { color: colors.$brue.$400 },
  navigation: { color: colors.$neutral.$900 },
  inverse: { color: colors.$neutral.$000 },
  disabled: { color: colors.$neutral.$600 },
  info: { color: colors.$neutral.$800 },
} satisfies DripsyBaseTheme['text'];

export function getLetterSpacing(fontSize: number) {
  return -fontSize * 0.03;
}

export const textSizeVariants = {
  // Size variants, the array represents the font size in
  // [mobile, tablet, desktop]. See https://www.dripsy.xyz/#style-once-run-anywhere
  display1: {
    fontSize: [fontScales.$48, fontScales.$64, fontScales.$64],
    lineHeight: [fontScales.$60, fontScales.$76, fontScales.$76],
    fontWeight: fontWeights.semiBold,
    letterSpacing: [
      getLetterSpacing(fontScales.$48),
      getLetterSpacing(fontScales.$64),
      getLetterSpacing(fontScales.$64),
    ],
  },
  display2: {
    fontSize: [fontScales.$40, fontScales.$56, fontScales.$56],
    lineHeight: [fontScales.$48, fontScales.$64, fontScales.$64],
    fontWeight: fontWeights.semiBold,
    letterSpacing: [
      getLetterSpacing(fontScales.$40),
      getLetterSpacing(fontScales.$56),
      getLetterSpacing(fontScales.$56),
    ],
  },
  heading1: {
    fontSize: [fontScales.$36, fontScales.$40, fontScales.$40],
    lineHeight: [fontScales.$44, fontScales.$52, fontScales.$52],
    fontWeight: fontWeights.semiBold,
    letterSpacing: [
      getLetterSpacing(fontScales.$36),
      getLetterSpacing(fontScales.$40),
      getLetterSpacing(fontScales.$40),
    ],
  },
  heading2: {
    fontSize: fontScales.$28,
    lineHeight: fontScales.$36,
    fontWeight: fontWeights.semiBold,
    letterSpacing: getLetterSpacing(fontScales.$28),
  },
  heading3: {
    fontSize: fontScales.$24,
    lineHeight: fontScales.$32,
    fontWeight: fontWeights.semiBold,
    letterSpacing: getLetterSpacing(fontScales.$24),
  },
  heading4: {
    fontSize: fontScales.$20,
    lineHeight: fontScales.$28,
    fontWeight: fontWeights.semiBold,
    letterSpacing: getLetterSpacing(fontScales.$20),
  },
  heading5: {
    fontSize: fontScales.$16,
    fontWeight: fontWeights.semiBold,
    letterSpacing: getLetterSpacing(fontScales.$16),
  },
  heading6: {
    fontSize: fontScales.$14,
    fontWeight: fontWeights.semiBold,
    letterSpacing: getLetterSpacing(fontScales.$14),
  },
  body1: {
    fontSize: fontScales.$16,
    lineHeight: fontScales.$24,
    fontWeight: fontWeights.regular,
    letterSpacing: getLetterSpacing(fontScales.$16),
  },
  body2: {
    fontSize: fontScales.$14,
    lineHeight: fontScales.$20,
    fontWeight: fontWeights.regular,
    letterSpacing: getLetterSpacing(fontScales.$14),
  },
  body3: {
    fontSize: fontScales.$12,
    lineHeight: fontScales.$16,
    fontWeight: fontWeights.regular,
    letterSpacing: getLetterSpacing(fontScales.$12),
  },
  body4: {
    fontSize: fontScales.$10,
    lineHeight: fontScales.$16,
    fontWeight: fontWeights.regular,
    letterSpacing: getLetterSpacing(fontScales.$10),
  },
  label1: {
    fontSize: fontScales.$16,
    lineHeight: fontScales.$24,
    fontWeight: fontWeights.regular,
  },
  label2: {
    fontSize: fontScales.$14,
    lineHeight: fontScales.$24,
    fontWeight: fontWeights.regular,
  },
  label3: {
    fontSize: fontScales.$12,
    lineHeight: fontScales.$16,
    fontWeight: fontWeights.regular,
  },
} satisfies DripsyBaseTheme['text'];

// Note that these variants need to match the button variants (see ./button.tsx)
export const textLabelVariants = {
  primaryLabel: {
    ...textVariants.inverse,
    fontWeight: fontWeights.semiBold,
  },
  primaryActiveLabel: textVariants.inverse,
  primaryHoverLabel: textVariants.inverse,
  primaryDisabledLabel: {
    ...textVariants.disabled,
    cursor: 'default',
  },

  secondaryLabel: { ...textVariants.primary, fontWeight: fontWeights.semiBold },
  secondaryActiveLabel: textVariants.primary,
  secondaryHoverLabel: textVariants.primary,
  secondaryDisabledLabel: {
    ...textVariants.secondaryDisabled,
    cursor: 'default',
  },

  tertiaryLabel: { ...textVariants.tertiary, fontWeight: fontWeights.semiBold },
  tertiaryActiveLabel: {
    ...textVariants.primary,
    textDecorationLine: 'underline',
  },
  tertiaryHoverLabel: {
    ...textVariants.primary,
    textDecorationLine: 'underline',
  },
  tertiaryDisabledLabel: {
    ...textVariants.tertiaryDisabled,
    cursor: 'default',
  },

  strokeLabel: { ...textVariants.primary, fontWeight: fontWeights.semiBold },
  strokeActiveLabel: textVariants.tertiary,
  strokeHoverLabel: textVariants.tertiary,
  strokeDisabledLabel: {
    ...textVariants.secondaryDisabled,
    cursor: 'default',
  },

  specialLabel: { ...textVariants.inverse, fontWeight: fontWeights.semiBold },
  specialActiveLabel: textVariants.inverse,
  specialHoverLabel: textVariants.inverse,
  specialDisabledLabel: { ...textVariants.special, cursor: 'default' },
} satisfies DripsyBaseTheme['text'];
