import { DripsyBaseTheme } from 'dripsy';

import { colors } from './color';

export const borderColors = {
  primary: { borderColor: colors.$neutral.$400 },
  secondary: { borderColor: colors.$neutral.$300 },
  info: { borderColor: colors.$neutral.$300 },
  success: { borderColor: colors.$green.$200 },
  successHover: { borderColor: colors.$green.$300 },
  successActive: { borderColor: colors.$green.$300 },
  warning: { borderColor: colors.$orange.$200 },
  warningHover: { borderColor: colors.$orange.$300 },
  warningActive: { borderColor: colors.$orange.$300 },
  error: { borderColor: colors.$red.$200 },
  errorHover: { borderColor: colors.$red.$300 },
  errorActive: { borderColor: colors.$red.$300 },
  bottom: { borderColor: colors.$neutral.$300 },
  bottomSecondary: { borderColor: colors.$neutral.$200 },
} satisfies DripsyBaseTheme['borders'];

export const borderRadiuses = {
  $xs: { borderRadius: 4 },
  $sm: { borderRadius: 8 },
  $md: { borderRadius: 12 },
  $lg: { borderRadius: 16 },
  $xl: { borderRadius: 20 },
} as const satisfies DripsyBaseTheme['borders'];
