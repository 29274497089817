import * as Dripsy from 'dripsy';
import { type PropsWithChildren } from 'react';

import { CustomSxProp, ThemeProvider } from '../../theme';
import { WithCommonProps } from '../../utils/types';

type ViewProps = WithCommonProps<{
  sx?: CustomSxProp;
}>;

export const View = ({
  sx,
  children,
  testID,
}: PropsWithChildren<ViewProps>) => (
  <ThemeProvider>
    <Dripsy.View sx={sx} testID={testID}>
      {children}
    </Dripsy.View>
  </ThemeProvider>
);
