import { DripsyBaseTheme } from 'dripsy';

export const sizes = {
  $8: 8,
  $12: 12,
  $16: 16,
  $24: 24,
  $32: 32,
  $36: 36,
  $40: 40,
  $80: 80,
  $320: 320,
  $456: 456,
  $760: 760,
} satisfies DripsyBaseTheme['sizes'];
