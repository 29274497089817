import { DripsyBaseTheme } from 'dripsy';

export const shadows = {
  $100: {
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 16,
    shadowOpacity: 0.04,
    shadowColor: 'rgb(0, 0, 0)',
  },
  $200: {
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 20,
    shadowOpacity: 0.06,
    shadowColor: 'rgb(0, 0, 0)',
  },
  $300: {
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 40,
    shadowOpacity: 0.08,
    shadowColor: 'rgb(0, 0, 0)',
  },
  $400: {
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 24,
    shadowOpacity: 0.1,
    shadowColor: 'rgb(0, 0, 0)',
  },
  $500: {
    shadowOffset: { width: 0, height: 6 },
    shadowRadius: 32,
    shadowOpacity: 0.12,
    shadowColor: 'rgb(0, 0, 0)',
  },
} satisfies DripsyBaseTheme['shadows'];
