"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_native_1 = require("react-native");
var useDimensions = function () {
    var _a = react_1.useState(react_native_1.Dimensions.get('window')), dimensions = _a[0], setDimensions = _a[1];
    var onChange = function (_a) {
        var window = _a.window;
        setDimensions(window);
    };
    react_1.useEffect(function () {
        var subscription = react_native_1.Dimensions.addEventListener('change', onChange);
        return function () {
            // Using removeEventListener is deprecated in react-native > 0.65 and will throw warning. Use .remove() if available.
            return subscription && subscription.remove
                ? subscription.remove()
                : react_native_1.Dimensions.removeEventListener('change', onChange);
        };
    }, []);
    return dimensions;
};
exports.default = useDimensions;
