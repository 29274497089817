import { DripsyBaseTheme } from 'dripsy';
import { Platform } from 'react-native';

import { colors } from './color';
import { sizes } from './size';
import { spacings } from './spacing';

type ButtonStyles = NonNullable<DripsyBaseTheme['buttons']>[string];

const commonStyles = (
  Platform.OS === 'web' ? { width: 'fit-content' } : {}
) satisfies ButtonStyles;

export const buttonVariants = {
  primary: {
    ...commonStyles,
    backgroundColor: colors.$neutral.$1000,
  },
  secondary: {
    ...commonStyles,
    backgroundColor: colors.$neutral.$400,
  },
  tertiary: {
    ...commonStyles,
    backgroundColor: colors.$transparent,
  },
  stroke: {
    ...commonStyles,
    backgroundColor: colors.$neutral.$000,
    borderWidth: 1.6,
    borderColor: colors.$neutral.$1000,
  },
  special: {
    ...commonStyles,
    backgroundColor: colors.$brink.$400,
  },
} satisfies DripsyBaseTheme['buttons'];

export const buttonStates = {
  primaryHover: {
    backgroundColor: colors.$neutral.$900,
  },
  primaryActive: {
    backgroundColor: colors.$neutral.$800,
  },
  primaryDisabled: {
    backgroundColor: colors.$neutral.$300,
  },
  secondaryHover: {
    backgroundColor: colors.$neutral.$500,
  },
  secondaryActive: {
    backgroundColor: colors.$neutral.$600,
  },
  secondaryDisabled: {
    backgroundColor: colors.$neutral.$200,
  },
  tertiaryDisabled: {
    backgroundColor: colors.$transparent,
  },
  tertiaryHover: {
    backgroundColor: colors.$transparent,
  },
  tertiaryActive: {
    backgroundColor: colors.$transparent,
  },
  strokeHover: {
    backgroundColor: colors.$neutral.$200,
    borderColor: colors.$neutral.$700,
  },
  strokeActive: {
    backgroundColor: colors.$neutral.$200,
    borderColor: colors.$neutral.$900,
  },
  strokeDisabled: {
    backgroundColor: colors.$neutral.$000,
    borderColor: colors.$neutral.$400,
  },
  specialHover: {
    backgroundColor: colors.$brink.$300,
  },
  specialActive: {
    backgroundColor: colors.$brink.$500,
  },
  specialDisabled: {
    backgroundColor: colors.$brink.$200,
  },
} satisfies DripsyBaseTheme['buttons'];

export const buttonShapes = {
  capsule: { borderRadius: sizes.$32 },
  square: { borderRadius: sizes.$8 },
} satisfies DripsyBaseTheme['buttons'];

export const buttonSizes = {
  xs: {
    paddingTop: spacings.$8,
    paddingBottom: spacings.$8,
    paddingRight: spacings.$12,
    paddingLeft: spacings.$12,
  },
  sm: {
    paddingTop: spacings.$12,
    paddingBottom: spacings.$12,
    paddingRight: spacings.$16,
    paddingLeft: spacings.$16,
  },
  md: {
    paddingTop: spacings.$12,
    paddingBottom: spacings.$12,
    paddingRight: spacings.$24,
    paddingLeft: spacings.$24,
  },
  lg: {
    paddingTop: spacings.$16,
    paddingBottom: spacings.$16,
    paddingRight: spacings.$24,
    paddingLeft: spacings.$24,
  },
  icon: {
    padding: spacings.$8,
    // Android doesn't allow a percentage value for this.
    // See https://github.com/react-native-community/discussions-and-proposals/issues/577#issuecomment-1966412624
    // So for the interim, this hack achieves the same purpose as "50%" == circle button.
    borderRadius: 9999,
  },
  iconCompact: {
    // See above comment
    borderRadius: 9999,
  },
} satisfies DripsyBaseTheme['buttons'];
