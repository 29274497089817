import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '../theme/breakpoints';

type Breakpoint = 'mobile' | 'tablet' | 'desktop';

/** Returns the breakpoint based on the viewport size */
export function useBreakpoint(): Breakpoint {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });
  const isTablet = useMediaQuery({ minWidth: breakpoints.tablet });
  const isMobile = useMediaQuery({ minWidth: breakpoints.mobile });

  switch (true) {
    case isDesktop:
      return 'desktop';
    case isTablet:
      return 'tablet';
    case isMobile:
      return 'mobile';
    default:
      return 'desktop';
  }
}
