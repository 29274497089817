import { DripsyBaseTheme } from 'dripsy';

const colorPrimitives = {
  $transparent: 'transparent',
  $domainGreen: '#0EA800',
  $cbaYellow: '#FFCC00',
  /** white -> greys -> (almost) black */
  $neutral: {
    $000: '#FFFFFF',
    $100: '#F7F7F7',
    $200: '#EFEFEF',
    $300: '#DEDEDE',
    $400: '#CCCCCC',
    $500: '#B8B8B8',
    $600: '#A2A2A2',
    $700: '#888888',
    $800: '#676767',
    $900: '#353535',
    $1000: '#101010',
  },
  $yellow: {
    $100: '#FDF8ED',
    $200: '#FAE0A8',
    $300: '#FFD979',
    $400: '#FFCC00',
    $500: '#665200',
  },
  $orange: {
    $100: '#FCEFE8',
    $200: '#FBD3BA',
    $300: '#FFAE6F',
    $400: '#FF9500',
    $500: '#7D4900',
  },
  $red: {
    $100: '#FBEFF0',
    $200: '#EFC0C1',
    $300: '#E17D7F',
    $400: '#D70015',
    $500: '#87000D',
  },
  $blue: {
    $100: '#F0F2FA',
    $200: '#C1CCEA',
    $300: '#7F9BDA',
    $400: '#0066CC',
    $500: '#004284',
  },
  $green: {
    $100: '#EFF3F0',
    $200: '#C0D1C1',
    $300: '#7DA680',
    $400: '#007D1B',
    $500: '#005011',
  },
  $brellow: {
    $100: '#FFF4E7',
    $200: '#FFE9CC',
    $300: '#FFDDAD',
    $400: '#F5C05A',
    $500: '#927236',
  },
  $brink: {
    $100: '#FEECF2',
    $200: '#FDD9E6',
    $300: '#FAB2CD',
    $400: '#EB88B1',
    $500: '#8F4765',
  },
  $brurple: {
    $100: '#F6EEFB',
    $200: '#ECDDF7',
    $300: '#DBBAF0',
    $400: '#B965E4',
    $500: '#7F459D',
  },
  $brue: {
    $100: '#EAF1FF',
    $200: '#CDDCF7',
    $300: '#99BDF7',
    $400: '#61A6F9',
    $500: '#356094',
  },
  $alpha: {
    $000: '#00000000',
    $100: '#00000019',
    $200: '#00000033',
    $300: '#0000004C',
    $400: '#00000066',
    $500: '#0000007F',
    $600: '#00000099',
    $700: '#000000B2',
    $800: '#000000CC',
    $900: '#000000E5',
  },
} satisfies DripsyBaseTheme['colors'];

/**
 * This colors object satisfies the Theme interface from Dripsy
 * but from usage seems to be not working.
 * e.g. `sx={{ bg: '$success' }}` does not resolve.
 *
 * This might because colors object should be a flat object.
 * Nested object from the typing is only supported by `theme-ui`,
 * a dependency of dripsy and the one that provide the nested
 * typing.
 * See https://www.dripsy.xyz/usage/theming/color-modes#with-dripsy
 *
 * For now, the workaround is to use the color from theme object
 * instead of specifying a string key in the style.
 * e.g. `sx={(theme) => ({ bg: theme.colors.$background.$success })}`
 *
 * TODO: Fix color resolution by flattening the colors object.
 */
export const colors = {
  ...colorPrimitives,
  $background: {
    $secondary: colorPrimitives.$neutral.$200,
    $tertiary: colorPrimitives.$neutral.$300,
    $inverse: colorPrimitives.$neutral.$1000,
    $transparent: colorPrimitives.$alpha.$600,
    $success: colorPrimitives.$green.$100,
    $warning: colorPrimitives.$brellow.$100,
    $error: colorPrimitives.$red.$100,
  },
} satisfies DripsyBaseTheme['colors'];
