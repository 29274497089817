import { DripsyBaseTheme } from 'dripsy';

import { sizes } from './size';
import { spacings } from './spacing';

export const layoutMargins = {
  // The array represents [mobile, tablet, desktop]
  // See https://www.dripsy.xyz/#style-once-run-anywhere
  xsMargin: { margin: [spacings.$4, spacings.$8, spacings.$12] },
  smMargin: { margin: [spacings.$8, spacings.$16, spacings.$24] },
  mdMargin: { margin: [spacings.$16, spacings.$24, spacings.$32] },
  lgMargin: { margin: [spacings.$24, spacings.$32, spacings.$40] },
  xlMargin: { margin: [spacings.$32, spacings.$48, spacings.$64] },
} satisfies DripsyBaseTheme['layout'];

export const layoutContainers = {
  device: { width: ['100%', sizes.$456, sizes.$760] },
} satisfies DripsyBaseTheme['layout'];
